import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

const NavBar = ({ homeClick }) => {
  const startContent = (
    <>
      <h1>Phennx.io</h1>
    </>
  );

  const centerContent = (
    <div className="flex flex-wrap align-items-center gap-3">
      <Button className="p-link inline-flex justify-content-center align-items-center text-white h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200" onClick={homeClick}>
        <i className="pi pi-home text-2xl"></i>
      </Button>

    </div>
  );

  const endContent = (
    <>
      <div className="flex align-items-center gap-2">

      </div>
    </>
  );

  return (
    <div className='navbar mt-4' style={{ borderRadius: '80px' }}>
      <Toolbar start={startContent} center={centerContent} end={endContent} style={{ borderRadius: '80px' }} />
    </div>
  );
};

export default NavBar;

import React, { useRef } from 'react';
import NavBar from './components/NavBar';
import HeroSection from './components/HeroSection';
import ContactForm from './components/ContactForm';

function App() {
  const heroRef = useRef(null);
  const contactFormRef = useRef(null);

  const scrollToHero = () => {
    heroRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <NavBar homeClick={scrollToHero} />
      <HeroSection ref={heroRef} onStayInformedClick={scrollToContactForm} />
      <ContactForm ref={contactFormRef} />
    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { isEmail } from 'validator';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { countries as countriesList } from 'countries-list';

const ContactForm = React.forwardRef((props, ref) => {
  const { register, handleSubmit, formState: { errors } } = useForm({

  });
  const toast = useRef(null);
  const companySizes = [
    { label: '1-50', value: '1-50' },
    { label: '51-100', value: '51-100' },
    { label: '101-500', value: '101-500' },
    { label: '501-1000', value: '501-1000' },
    { label: '1001-2500', value: '1001-2500' },
    { label: '2500+', value: '2500+' }
  ];
  const countries = Object.values(countriesList).map(country => ({
    label: country.name,
    value: country.name
  })).sort((a, b) => a.label.localeCompare(b.label));
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [country, setCountry] = useState('');

  const onSubmit = data => {
    // TODO: Connect API
    setFirstName('')
    setLastName('')
    setCompanyName('')
    setBusinessEmail('')
    setCompanySize('')
    setCountry('')
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Contact info submitted', life: 3000 });
  };

  register("businessEmail", {
    required: "Business email is required",
    validate: value => isEmail(value) || "Invalid email address"
  });

  useEffect(() => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      document.getElementById(firstErrorField).focus();
    }
  }, [errors]);  

  return (
    <div ref={ref} className='flex flex-row pt-8 pb-8 m-8 justify-content-center'>
      <Toast ref={toast} />
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid" style={styles.form}>
      <div className="p-field mb-4">
                    <label htmlFor="firstName">First Name</label>
                    <InputText id="firstName" {...register("firstName", { required: "First name is required" })} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    {errors.firstName && <small className="p-error">{errors.firstName.message}</small>}
                </div>
                <div className="p-field mb-4">
                    <label htmlFor="lastName">Last Name</label>
                    <InputText id="lastName" {...register("lastName", { required: "Last name is required" })} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    {errors.lastName && <small className="p-error">{errors.lastName.message}</small>}
                </div>
                <div className="p-field mb-4">
                    <label htmlFor="companyName">Company Name</label>
                    <InputText id="companyName" {...register("companyName")} value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                </div>
                <div className="p-field mb-4">
                    <label htmlFor="businessEmail">Business Email</label>
                    <InputText id="businessEmail" {...register("businessEmail", { required: "Business email is required", pattern: { value: /^\S+@\S+\.\S+$/, message: "Email is invalid" } })} value={businessEmail} onChange={(e) => setBusinessEmail(e.target.value)} />
                    {errors.businessEmail && <small className="p-error">{errors.businessEmail.message}</small>}
                </div>
                <div className="p-field mb-4">
                    <label htmlFor="companySize">Company Size</label>
                    <Dropdown id="companySize" options={companySizes} value={companySize} onChange={(e) => setCompanySize(e.value)} />
                </div>
                <div className="p-field mb-4">
                    <label htmlFor="country">Country</label>
                    <Dropdown id="country" options={countries} value={country} onChange={(e) => setCountry(e.value)} />
                </div>
        <Button type="submit" label="Submit" className="p-button-rounded" style={styles.button} />
      </form>
    </div>
  );
});

// Styling for the component
const styles = {
  button: {
    fontSize: '1rem',
    padding: '10px 20px',
    background: 'orange',
    backgroundColor: 'orange',
    border: 'none',
    borderRadius: '5px'
  },
  form: {
    width: '100%',
    maxWidth: '500px'
  }  
};

export default ContactForm;
